.banner{
    height: 400px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;

    .content{
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        height: 400px;
        width: 1235px;
        margin: auto;

        .left{
            width: 50%;
            margin: auto;
            display: block;
            

            h1{
                color: #973131;
                font-size: 56px;
                text-align: center;
                font-weight: bolder;
            }
            p{
                color: #fff;
                font-size: 20px;
                text-align: center;
            }
            .btn{
                color: #fff;
                background-color: brown;
                font-weight: bolder;
                font-size: 16px;
                text-decoration: none;
                margin: auto;
                display: block;
                width: 200px;
                transition: 0.5s;



                &:hover{
                    transform: scale(1.1);
                    transition: 0.5s;
                }
            }
        }
    }
}

@media screen and (max-width: 932px) and (min-width: 360px){
    .banner{
        width: auto;
        background-size: cover;
        background-attachment: fixed;
        height: auto;

        .content{
            width: auto;

            .left{
                width: auto;

                

                h1{
                    font-size: 45px;
                }
                p{
                    font-size: 15px;
                }
            }
        }
    }
}