.info{
    width: 1235px;
    margin: auto;
    display: block;
    padding-top: 3%;

    .content{
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;

        h1{
            color: #973131;
            font-size: 50px;
            margin-bottom: 10px;
            font-weight: 700;
        }
        p{
            color: #fff;
            font-size: 18px;
        }
        .left{
            width: 50%;
            height: 100%;
        }
        .center{
            width: 50%;
            height: 100%;
        }
        .right{
            width: 50%;
            height: 100%;
        }
    }
}

@media screen and (max-width: 932px) and (min-width: 360px){
    .info{
        width: auto;
        ;

        .content{
            width: auto;
            display: block;
            text-align: center;

            .left{
                width: auto;
            }
            .center{
                width: auto;
            }
            .right{
                width: auto;
            }

            h1{
                font-size: 40px;
                margin-bottom: 10px;

            }
            p{
                font-size: 15px;
            }
        }
    }
    
}