.App{
    margin: 0;
    padding: 0;
    margin: auto;
    display: block;
    width: 100%;
    min-height: 100dvh;
}

@media screen and (max-width: 932px) and (min-width: 360px) {
    .App{
        width: auto;
    }
}