.categories{
    width: 1235px;
    margin: auto;
    padding-top: 3%;

    .content{
        width: 100%;
      
        text-align: center;

        h1{
            color: #fff;
            margin-bottom: 10px;
            font-weight: 700;
        }
        p{
            color: #fff;
            font-size: 18px;
            width: 95%;
            text-align: center;
            margin: auto;
        }

        .box{
            display: flex;
            margin: auto;
            
            .left{
                width: 50%;
                height: 350px;
                padding-top: 3%;
                
            }
            .right{
                width: 50%;
                height: 350px;
            }
        }
    }
}

@media screen and (max-width: 932px) and (min-width: 360px){
    .categories{
        width: auto;

        .content{
            width: auto;

            h1{
                font-size: 45px;
            }
            p{
                font-size: 15px;
            }

            .box{
                width: auto;
                display: block;

                .left{
                    width: auto;
                    height: 300px;
                }
                .right{
                    width: auto;
                    height: 300px;
                }
            }
        }
    }
    
}