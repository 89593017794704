.course{
    width: 100%;
    height: 100vh;
    background-color: #1E1E1E;

    .content{
        padding-top: 5%;

        h1{
            color: #973131;
            text-align: center;
        }
    }
}