.teachersCarousel{
    width: 1235px;
    margin: auto;
    padding-top: 3%;

    .content{
        width: 100%;

        h4{
            color: #973131;
            font-size: 30px;
            height: 20px;
            text-align: center;
        }
    }
}

@media screen and (max-width: 932px) and (min-width: 360px){
    .teachersCarousel{
        width: auto;
    }
}