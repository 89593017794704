.announcement{
    width: 100%;
    padding-top: 3%;

    .content{
        width: 1235px;
        margin: auto;
        display: flex;
        h4{
            background-color: #973131;
            color: #fff;
            padding: 10px;
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            
        }

        .left{
            width: 70%;
            margin: auto;
            display: block;


            .announce{
                width: 100%;
                height: 400px;
                position: relative;
                overflow: hidden;
                margin-bottom: 3%;
                background-repeat: no-repeat;
                background-size: cover;
                text-align: center;

                &::before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.605);
                }

                h5{
                    color: #fff;
                    font-size: 36px;
                    font-weight: 700;
                    position: absolute;
                    left: 50%;
                    top: 40%;
                    text-shadow: 3px 3px 5px rgb(0, 0, 0);
                    transform: translate(-50%, -50%);
                    width: 500px;
                    

                }
                p{
                    color: #fff;
                    font-size: 20px;
                    position: absolute;
                    left: 50%;
                    top: 65%;
                    text-shadow: 3px 3px 5px rgb(0, 0, 0);
                    transform: translate(-50%, -50%);
                }

                .btn{
                    position: absolute;
                    color: #fff;
                    font-size: 18px;
                    font-weight: 500;
                    background-color: #973131;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
                    transition: 0.5s;
                    width: 200px;
                    left: 50%;
                    top: 80%;
                    transform: translate(-50%, -50%);

                    &:hover{
                        background-color: #fff;
                        color: #973131;
                        cursor: pointer;
                        transition: 0.5s;
                    }
                }
            }
        }
        .right{
            width: 30%;
            border-left: #973131 solid 5px;

            .video{
                width: 100%;
                padding-top: 3%;
                
                iframe{
                    width: 100%;
                    height: 180px;
                    margin-top: 3%;
                }
    
                h5{
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    text-align: center;
                    margin-top: 1%;
                }
            }
        }
    }
}

@media screen and (max-width: 932px) and (min-width: 360px){
    .announcement{
        width: auto;

        .content{
            width: auto;
            display: block;

            .left{
                width: auto;
                .announce{
                    width: auto;
                    height: 300px;

                    h5{
                        font-size: 24px;
                        width: 300px;
                    }
                    p{
                        font-size: 18px;
                    }
                }
            }

            .right{
                width: auto;
                border-left: none;
                display: block;

                .video{
                    width: auto;
                    padding-bottom: 2%;
                    iframe{
                        width: auto;
                        height: 200px;
                        display: block;
                        margin: auto;
                    }

                    h5{
                        font-size: 18px;
                        width: 300px;
                        margin: auto;
                        margin-top: 3%;
                    }
                }
            }
        }
    }
    
}