.header{
    width: 100%;
    height: 70px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    top: 0;
    position: sticky;
    background-color: #fff;
    z-index: 10;
    .nav{
        margin: auto;
        display: block;
        width: 1235px;
        ul{
            margin: 0;
            padding: 0;
            display: flex;

            img{
                width: 80px;
                transition: 0.3s;

                &:hover{
                    cursor: pointer;
                    transform: scale(1.1);
                    transition: 0.5s;
                }
            }
            li{
                display: block;
                padding: 10px;
                margin-left: 2%;

                .links{
                    text-decoration: none;
                    color: rgb(0, 0, 0);
                    
                }

                a{
                    text-decoration: none;
                    color: rgb(0, 0, 0);
                    
                }
            }
        }
    }
}

@media screen and (max-width: 932px) and (min-width: 360px){
    .header{
        width: auto;
        .nav{
            width: auto;
            ul{

                img{
                    width: 70px;
                }
                li{
                    

                    .links{
                        font-size: 14px;
                        
                    }
                    a{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    
}